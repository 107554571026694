import { Accordion, Button } from "react-bootstrap";
import arrayUtilities from "../../../utilities/arrayUtilities";

export default function MapOutputToolbox(props) {

    const onDownloadMapPackage = function() {
        if (props.onDownloadMapPackage) {
            props.onDownloadMapPackage();
        }
    }

    const onDownloadData = function(exportFormat) {
        if (props.onDownloadData) {
            props.onDownloadData(exportFormat)
        }
    }

    const onDownloadScreenshot = function() {
        if (props.onDownloadScreenshot) {
            props.onDownloadScreenshot()
        }
    }

    return <Accordion.Item eventKey='6'>
        <Accordion.Header><i className='fi-download-file me-2' /> Map Output</Accordion.Header>
        <Accordion.Body>
            <div className='d-flex flex-column my-2'>
                <Button size='sm' variant="link" className="d-flex flex-grow-1 justify-content-between align-items-center p-0 mb-2" onClick={onDownloadScreenshot}>Print Map <i className='fi-image'></i></Button>
                <Button size='sm' variant="link" className="d-flex flex-grow-1 justify-content-between align-items-center p-0 mb-2" disabled={props.sketch === null || arrayUtilities.isNullOrEmpty(props.sketch.limits) || props.isDownloadingMapPackage} onClick={onDownloadMapPackage}>Download Map Package <i className='fi-folder'></i></Button>
                <Button size='sm' variant="link" className="d-flex flex-grow-1 justify-content-between align-items-center p-0 mb-2" onClick={() => onDownloadData(1)}>Download as KML <i className='fi-code'></i></Button>
                <Button size='sm' variant="link" className="d-flex flex-grow-1 justify-content-between align-items-center p-0 mb-2" onClick={() => onDownloadData(2)}>Download as Shapefile <i className='fi-layers'></i></Button>
                <Button size='sm' variant="link" className="d-flex flex-grow-1 justify-content-between align-items-center p-0 mb-2" onClick={() => onDownloadData(3)}>Download as CSV <i className='fi-header'></i></Button>
                <Button size='sm' variant="link" className="d-flex flex-grow-1 justify-content-between align-items-center p-0 mb-2" onClick={() => onDownloadData(4)}>Download as CAD <i className='fi-file-clean'></i></Button>
            </div>
        </Accordion.Body>
    </Accordion.Item>

}