import { useState, useEffect } from "react";
import stringUtilities from "../utilities/stringUtilities";
import { WorkflowAddEdit } from "../components/common/formsCreateEdit/workflowAddEdit";
import useApi from "../utilities/useApi";
import { useParams, useNavigate } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import Textbox from "./form/textbox";

export default function AddWorkflow() {
    const { api } = useApi();
    const navigate = useNavigate();
    const { organisationId, projectId } = useParams();
    const [name, setName] = useState("");
    const [newTemplateName, setNewTemplateName] = useState("");
    const [isComposite, setIsComposite] = useState(false);
    const [workflowTemplateId, setWorkflowTemplateId] = useState(null);
    const [workflowTemplates, setWorkflowTemplates] = useState([]);
    const [workflowType, setWorkflowType] = useState("9");
    const [hue, setHue] = useState(0);
    const [rgbColor, setRgbColor] = useState("#000000");
    const [order, setOrder] = useState("0");
    const [configuration, setConfiguration] = useState(null);
    const [canSave, setCanSave] = useState(false);
    const [canSaveTemplate, setCanSaveTemplate] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const initialiseWorkflowTypeValue = (type) => {
        return (type !== 9 && type !== 10) ? 9 : type
    }

    const handleSubmit = () => {
        api.addWorkflow(
            organisationId, projectId,
            name, parseInt(order), configuration, parseInt(workflowType), parseInt(hue), rgbColor, workflowTemplateId, isComposite,
            () => navigate(`/${ organisationId }/projects/${ projectId }/workflows`),
            () => { }
        )
    }

    const resetTemplateValues = () => {
        setWorkflowType("9");
        setHue(0);
        setRgbColor("#000000");
        setOrder("0");
        setConfiguration(null);
    }

    useEffect(() => {
        api.getWorkflowTemplates(
            organisationId,
            data => setWorkflowTemplates(data),
            () => { }
        )
    }, [])

    useEffect(() => {
        setCanSave(!stringUtilities.isNullOrEmpty(name) && !stringUtilities.isNullOrEmpty(order) && !stringUtilities.isNullOrEmpty(workflowType) && hue !== undefined)
        setCanSaveTemplate(!stringUtilities.isNullOrEmpty(order) && !stringUtilities.isNullOrEmpty(workflowType) && hue !== undefined)
    }, [name, order, workflowType, hue])

    const handleWorkflowTemplateChange = (templateId) => {
        if (!stringUtilities.isNullOrEmpty(templateId)) {
            setWorkflowTemplateId(templateId);
            const selectedTemplate = workflowTemplates.find(item => item.id === templateId);
            setWorkflowType(String(initialiseWorkflowTypeValue(selectedTemplate.workflowTypeId)));
            setHue(selectedTemplate.hue);
            setRgbColor(stringUtilities.checkColorHash(selectedTemplate.color));
            setOrder(String(selectedTemplate.orderBy));
            setConfiguration(selectedTemplate.configuration);
        } else {
            setWorkflowTemplateId(null);
            resetTemplateValues();
        }
    }

    const onSaveTemplate = () => {
        api.addWorkflowTemplate(
            organisationId,
            newTemplateName, parseInt(order), configuration, parseInt(workflowType), parseInt(hue), rgbColor,
            () => navigate(`/${ organisationId }/projects/${projectId}/workflows`),
            () => {},
        )
    }

    return (
        <>
            <WorkflowAddEdit
                title="Add workflow"
                name={ name }
                setName={ setName }
                isComposite={ isComposite }
                setIsComposite={ setIsComposite }
                workflowTemplates={ workflowTemplates }
                workflowTemplateId={ workflowTemplateId }
                handleWorkflowTemplateChange={ templateId => handleWorkflowTemplateChange(templateId) }
                workflowType={ workflowType }
                setWorkflowType={ setWorkflowType }
                hue={ hue }
                setHue={ setHue }
                rgbColor={ rgbColor }
                setRgbColor={ setRgbColor }
                order={ order }
                setOrder={ setOrder }
                configuration={ configuration }
                setConfiguration={ setConfiguration }
                canSave={ canSave }
                canSaveTemplate={ canSaveTemplate }
                handleSubmit={ handleSubmit }
                handleCancel={ () => navigate(`/${ organisationId }/projects/${ projectId }/workflows`) }
                handleSaveTemplate={ () => setShowDeleteModal(true) }
            />

            <Modal show={ showDeleteModal } onHide={ () => {
                setShowDeleteModal(false);
            } } centered>
                <Modal.Header>
                    <Modal.Title>Save as template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SimpleBar autoHide={ false } className='simplebar-no-autohide pe-3' style={ { maxHeight: 'calc(100vh - 380px)' } }>
                        <Textbox label="Name" value={ newTemplateName } onChange={ setNewTemplateName } />
                    </SimpleBar>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant='secondary' onClick={ () => {
                        setShowDeleteModal(false);
                    } }>Cancel</Button>
                    <Button size="sm" variant="primary" onClick={ onSaveTemplate }>Save template</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}