import { useState } from 'react';
import { Col, Container, Row, Button, Form } from 'react-bootstrap';
import ReactSlider from "react-slider";
import stringUtilities from '../../../utilities/stringUtilities';

export const WorkflowAddEdit = ({ title, name, setName, isComposite, setIsComposite, workflowTemplates, workflowTemplateId, handleWorkflowTemplateChange, workflowType, setWorkflowType, hue, setHue, rgbColor, setRgbColor, order, setOrder, configuration, setConfiguration, canSave, handleSubmit, handleCancel, handleSaveTemplate, canSaveTemplate }) => {
    const [jsonInvalid, setJsonInvalid] = useState(false);
    
    return (
        <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2'>
            <Row>
                <Col className='mb-5'>
                    <div className='d-flex justify-content-between my-5'>
                        <h1 className="h2 m-0">{ title }</h1>
                    </div>
                    <div>
                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-md-3' htmlFor="workflow-name">
                                Name
                            </Form.Label>
                            <div className="col-md-9 d-flex">
                                <Form.Control size='sm' value={ name } type="text" onChange={ (e) => setName(e.target.value) } />
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-md-3' htmlFor="workflow-composite">
                                Composite
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <Form.Check type='switch' id="workflow-composite" checked={ isComposite } onChange={ (e) => setIsComposite(e.target.checked) } />
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-md-3' htmlFor="workflow-template">
                                Workflow Template
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <Form.Select size='sm' value={ workflowTemplateId } onChange={ (e) => {
                                    handleWorkflowTemplateChange(e.target.value)
                                } }>
                                    <option value="">Select a Template</option>
                                    {
                                        workflowTemplates
                                        .map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                    }
                                </Form.Select>
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-md-3' htmlFor="workflow-type">
                                Workflow Type
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <Form.Select disabled={workflowTemplateId} size='sm' value={ workflowType } onChange={ (e) => setWorkflowType(e.target.value) }>
                                    <option value="9">Non-Sensor</option>
                                    <option value="10">Sensor</option>
                                </Form.Select>
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-md-3' htmlFor="workflow-hue">
                                Hue
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <ReactSlider disabled={workflowTemplateId} className='range-slider range-slider-single' thumbClassName='range-slider-handle' trackClassName='range-slider-track' min={0} max={360} value={hue} ariaLabel={['Handle']} ariaValuetext={state => `Handle value ${state.valueNow}`} step={1} onChange={(e) => setHue(e)} renderThumb={(props, state) => (
                                    <div {...props}>
                                        <div className='range-slider-tooltip'>{state.valueNow}</div>
                                    </div>
                                )}
                                />
                                <div style={{backgroundColor: `hsl(${hue}, 100%, 50%)`, width: "200px", height: "40px", marginLeft: "1rem", borderRadius: "0.30rem"}} />
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-md-3' htmlFor="workflow-rgbcolor">
                                RGB Color
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <Form.Control disabled={workflowTemplateId} style={ { width: "100px", marginRight: "1rem" } } size='lg' value={ rgbColor } type="color" onChange={ (e) => setRgbColor(e.target.value) } />
                                <p className="m-0">{ rgbColor }</p>
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-md-3' htmlFor="workflow-order">
                                Order
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <Form.Control size='sm' value={ order } type="text" onChange={ (e) => setOrder(e.target.value) } />
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-md-3' htmlFor="workflow-configuration">
                                Configuration
                            </Form.Label>
                            <div className="col-md-9 d-flex flex-column">
                                <Form.Control disabled={workflowTemplateId} size='sm' value={ configuration } as="textarea" rows={ 10 } onChange={ (e) => setConfiguration(e.target.value) } />
                                {jsonInvalid && <small className='text-danger mt-1'>Invalid Configuration formatting. Please verify the JSON text is correctly formatted and re-submit.</small>}
                            </div>
                        </Form.Group>
                    </div>
                    <Form.Group className='mt-4 row justify-content-end'>
                        <div className="col-md-9">
                            <Button onClick={ handleCancel } variant='outline-primary' size="sm">
                                Cancel
                            </Button>
                            <Button disabled={ !canSave } onClick={ () => {                                
                                    if (!stringUtilities.isNullOrEmpty(configuration)) {
                                        try {
                                            JSON.parse(configuration);
                                            handleSubmit()
                                            setJsonInvalid(false);
                                        } catch (error) {
                                            setJsonInvalid(true);
                                        }
                                    } else handleSubmit()
                                } } size='sm' className='ms-2'>
                                Submit
                            </Button>
                            {handleSaveTemplate && 
                            <Button disabled={ !canSaveTemplate } onClick={ handleSaveTemplate } variant='danger' size='sm' className='ms-2'>
                                    Save as template
                            </Button>}
                        </div>
                    </Form.Group>
                </Col>
            </Row>
        </Container>
    )
}