import React, { useState, useEffect, useCallback, useRef } from 'react'
import { MapContainer, Pane } from 'react-leaflet'
import L from 'leaflet';
import { useParams } from 'react-router-dom';
import useApi from '../utilities/useApi';
import ArrayUtilities from '../utilities/arrayUtilities';
import stringUtilities from '../utilities/stringUtilities';
import Accordion from 'react-bootstrap/Accordion'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

import 'react-datepicker/dist/react-datepicker.css'

import RemoteControl from './map/remoteControl';
import postal from 'postal';
import Loading from './common/loading';
import Sketch from './map/sketch';
import DataPoint from './map/dataPoint';
import DataGeomtry from './map/dataGeometry';
import LimitsToolbox from './map/toolboxes/limitsToolbox';
import SketchesToolbox from './map/toolboxes/sketchesToolbox';
import FiltersToolbox from './map/toolboxes/filtersToolbox';
import arrayUtilities from '../utilities/arrayUtilities';
import DrawingToolsToolbox from './map/toolboxes/drawingToolsToolbox';
import DownloadMapPackage from './map/exports/downloadMapPackage';
import SettingsToolbox from './map/toolboxes/settingsToolbox';
import MapOutputToolbox from './map/toolboxes/mapOutputToolbox';
import AddressSearchToolbox from './map/toolboxes/addressSearchToolbox';

import cloneDeep from 'lodash.clonedeep';
import StampsToolbox from './map/toolboxes/stampsToolbox';
import geospatialUtilities from '../utilities/geospatialUtilities';
import Basemap from './map/basemap';
import { Alert, Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import ReactSlider from 'react-slider';
import DownloadData from './map/exports/downloadData';
import * as htmlToImage from 'html-to-image';
import moment from "moment";

var hash = require('object-hash');

export default function Map(props) {

    const MaxZoom = 25;

    const params = useParams();
    const mapRef = useRef(null);

    const projectId = params.projectId;
    const organisationId = stringUtilities.isNullOrEmpty(params.organisationId) ? props.organisationId : params.organisationId;

    const { api } = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [points, setPoints] = useState(null);
    const [pointsHash, setPointsHash] = useState(null);
    const [hiddenDataPoints, setHiddenDataPoints] = useState(0);
    const [lines, setLines] = useState(null);
    const [project, setProject] = useState(null);

    const [jobs, setJobs] = useState(null);
    const [selectedJobs, setSelectedJobs] = useState([]);

    const [sketchData, setSketchData] = useState(null);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const [sketches, setSketches] = useState(null);
    const [sketchId, setSketchId] = useState("");
    const [sketch, setSketch] = useState(null);
    const [sketchHash, setSketchHash] = useState(null);
    const [activeLimit, setActiveLimit] = useState(null);

    const [workflows, setWorkflows] = useState(null);
    const [workflowPoints, setWorkflowPoints] = useState([]);
    const [workflowLines, setWorkflowLines] = useState([]);
    const [workflowPointsLines, setWorkflowPointsLines] = useState([]);

    const [stamps, setStamps] = useState(null);
    const [tags, setTags] = useState(null);

    const [users, setUsers] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [zoomLocked, setZoomLocked] = useState(false);
    const [zoom, setZoom] = useState(4);
    const [center, setCenter] = useState({
        lat: 48.166667,
        lng: -100.166667
    });
    const [bounds, setBounds] = useState(null);
    const [dataBounds, setDataBounds] = useState(null);
    const [isDownloadingMapPackage, setIsDownloadingMapPackage] = useState(false);
    const [isDownloadingScreenshot, setIsDownloadingScreenshot] = useState(false);
    const [isDownloadingData, setIsDownloadingData] = useState(null);
    const [snappingTolerance, setSnappingTolerance] = useState(0.5);
    const [arrowScale, setArrowScale] = useState(1);
    const [symbolScale, setSymbolScale] = useState(1);
    const [basemap, setBasemap] = useState(Number(localStorage.getItem("basemap") ?? 0));
    const [printBasemap, setPrintBasemap] = useState(Number(localStorage.getItem("printBasemap") ?? 0));

    const onZoomChange = function (e) {
        setZoom(e);
    }

    const onAfterZoomChange = function (e) {

        setZoom(e);

        postal.publish({
            channel: "map",
            topic: "zoom.change",
            data: {
                zoom: e
            }
        });
    }

    const resetToLockedZoom = function () {
        if ((sketch.configuration?.zoom ?? 0) > 0) {
            setZoom(sketch.configuration.zoom);

            postal.publish({
                channel: "map",
                topic: "zoom.change",
                data: {
                    zoom: sketch.configuration.zoom
                }
            });
        }
    }

    const onAfterArrowScaleChange = function (arrowScale) {
        setArrowScale(arrowScale);

        if (sketch && sketch !== null) {
            onSaveSketch(sketch, true);
        }
    }

    const onBoundsChange = function (bounds, center) {
        setBounds(bounds);
        setCenter(center);
    }

    var _getDataAbortController = null;
    const getData = function (refresh) {

        if (refresh || (!isDownloadingMapPackage && (!dataBounds || dataBounds === null || (bounds && bounds !== null && !dataBounds.contains(bounds)) || hiddenDataPoints > 0))) {

            let theBounds = bounds && bounds !== null ? bounds : mapRef.current.getBounds();

            let n = theBounds.getNorth();
            let s = theBounds.getSouth();
            let e = theBounds.getEast();
            let w = theBounds.getWest();

            let width = geospatialUtilities.haversineDistance({
                lat: n,
                lng: w
            }, {
                lat: n,
                lng: e
            });

            let height = geospatialUtilities.haversineDistance({
                lat: n,
                lng: w
            }, {
                lat: s,
                lng: w
            });

            let ne = geospatialUtilities.shiftPoint({
                lat: n,
                lng: e
            }, -width, height);

            let sw = geospatialUtilities.shiftPoint({
                lat: s,
                lng: w
            }, width, -height);

            let getDataBounds = L.latLngBounds([ne.lat, ne.lng], [sw.lat, sw.lng]);

            setIsLoading(true);

            if (_getDataAbortController) {
                _getDataAbortController.abort()
            };

            _getDataAbortController = new AbortController();

            /* TODO: GET THE TIMEZONE OFFSET */
            api.getData(organisationId, projectId, selectedJobs, selectedUsers, null, getDataBounds.getNorth(), getDataBounds.getSouth(), getDataBounds.getWest(), getDataBounds.getEast(), dateFrom, dateTo, 0, 0, 0, false, _getDataAbortController.signal, (data) => {
                setPoints(data.items);
                setLines(ArrayUtilities.distinct(data.items.filter(x => x.lineId !== null).map(x => x.lineId)))
                setIsLoading(false);
                setHiddenDataPoints(data.totalCount - data.items.length);
            }, () => {
                setPoints(null);
                setLines(null);
                setIsLoading(false);
                setHiddenDataPoints(0)
            });

            setDataBounds(getDataBounds);
        }
    }

    const refreshSketches = function (selectSketchId) {
        api.getSketches(organisationId, projectId, (sketches) => {
            setSketches(sketches);
            setIsLoading(false);

            if (!stringUtilities.isNullOrEmpty(selectSketchId)) {
                setSketchId(selectSketchId);
            }
        })
    }

    const onSaveSketch = function (sketch, refresh) {

        sketch.configuration = {
            selectedJobs,
            dateFrom: stringUtilities.isNullOrEmpty(dateFrom) ? null : dateFrom,
            dateTo: stringUtilities.isNullOrEmpty(dateTo) ? null : dateTo,
            points: workflowPoints,
            lines: workflowLines,
            pointsLines: workflowPointsLines,
            arrowScale: arrowScale,
            symbolScale: symbolScale,
            data: sketchData,
            zoom: zoomLocked ? zoom : null
        };

        api.saveSketch(organisationId, projectId, sketchId, sketch, (data) => {

            setSketch(data);
            if (refresh) {
                setSketchHash(hash(data));
            }
        }, () => { })
    }

    const onSaveData = function (dataItem) {

        let newSketchData = [dataItem];

        if (!arrayUtilities.isNullOrEmpty(sketchData)) {

            let index = sketchData.findIndex(x => x.id === dataItem.id);
            if (index >= 0) {
                let copy = cloneDeep(sketchData);
                copy.splice(index, 1, dataItem);
                newSketchData = copy;

            } else {
                newSketchData = sketchData.concat([dataItem]);
            }
        }

        if (!sketch.configuration || sketch.configuration === null) {
            sketch.configuration = { data: newSketchData };
        } else {
            sketch.configuration.data = newSketchData;
        }

        api.saveSketch(organisationId, projectId, sketchId, sketch, () => {

        }, () => { })

        setSketchData(newSketchData);
    }

    const deleteSketch = function () {
        if (!stringUtilities.isNullOrEmpty(sketchId)) {
            setIsLoading(true);

            api.deleteSketch(organisationId, projectId, sketchId, () => {
                setSketches(sketches.filter(x => x.id !== sketchId));
                setSketchId(null);

                setIsLoading(false);
            }, () => {

                setIsLoading(false);
            });
        }
    }

    const refreshSelectedWorkflowLayers = function (sketch) {
        let allWorkflows = workflows?.map((x) => x.id);
        if (sketch && sketch !== null && sketch.configuration && sketch.configuration !== null) {

            if (arrayUtilities.isNullOrEmpty(sketch.configuration.points) && arrayUtilities.isNullOrEmpty(sketch.configuration.lines) && arrayUtilities.isNullOrEmpty(sketch.configuration.pointsLines)) {
                setWorkflowPoints(allWorkflows);
                setWorkflowLines(allWorkflows);
                setWorkflowPointsLines([]);
            } else {
                setWorkflowPoints(sketch.configuration.points);
                setWorkflowLines(sketch.configuration.lines);
                setWorkflowPointsLines(sketch.configuration.pointsLines);
            }

            if (sketch.configuration?.arrowScale === 0) {
                setArrowScale(1);
            } else {
                setArrowScale(sketch.configuration?.arrowScale ?? 1);
            }

            if (sketch.configuration?.symbolScale === 0) {
                setSymbolScale(1);
            } else {
                setSymbolScale(sketch.configuration?.symbolScale ?? 1);
            }
        } else {

            setWorkflowPoints(allWorkflows);
            setWorkflowLines(allWorkflows);
            setWorkflowPointsLines([]);
        }
    }

    //todo: move this to somewhere common, and probably use something better than window.timerid.
    const debounce = (fn, delay) => {
        return (...args) => {
            clearTimeout(window.timerId);
            window.timerId = setTimeout(() => {
                fn(...args);
            }, delay);
        }
    };

    const debounceGetData = useCallback(
        debounce(getData, 1500),
        [getData]
    );

    const debounceGetDataAndRefresh = useCallback(
        debounce(() => getData(true), 1500),
        [getData]
    );

    useEffect(() => {
        debounceGetData();
    }, [bounds])


    useEffect(() => {
        debounceGetDataAndRefresh();
    }, [selectedJobs, selectedUsers])

    useEffect(() => {
        setPointsHash(hash(points));
    }, [points]);

    useEffect(() => {
        refreshSelectedWorkflowLayers(sketch);
    }, [workflows])

    useEffect(() => {
        localStorage.setItem("printBasemap", printBasemap);
    }, [printBasemap])


    useEffect(() => {
        localStorage.setItem("basemap", basemap);
    }, [basemap])

    useEffect(() => {
        postal.publish({
            channel: "map",
            topic: (zoomLocked ? "zoom.disabled" : "zoom.enabled")
        });

        if (sketch && sketch !== null) {
            onSaveSketch(sketch, false);
        }
    }, [zoomLocked]);

    useEffect(() => {

        setSketchData(null);

        if (!stringUtilities.isNullOrEmpty(sketchId)) {
            setIsLoading(true);
            api.getSketch(organisationId, projectId, sketchId, (data) => {

                setSketch(data);
                setSketchHash(hash(data));
                setIsLoading(false);

                if (data && data !== null && data.configuration && data.configuration !== null) {
                    setSelectedJobs(data.configuration.selectedJobs);
                    setDateFrom(data.configuration.dateFrom);
                    setDateTo(data.configuration.dateTo);
                    setSketchData(data.configuration.data);
                    setArrowScale(data.configuration?.arrowScale ?? 1);

                    if ((data.configuration?.zoom ?? 0) > 0) {
                        setZoom(data.configuration.zoom);
                        setZoomLocked(true);
                    } else {
                        setZoomLocked(false);
                    }
                } else {
                    setZoomLocked(false);
                }

                refreshSelectedWorkflowLayers(data);

                postal.publish({
                    channel: "map",
                    topic: "sketch.loaded",
                    data: {
                        sketch: data
                    }
                });
            }, () => {

            })
        } else {
            setSketch(null);
            setSketchHash(null);
            setZoomLocked(false);
        }
    }, [sketchId])

    useEffect(() => {

        const subscriptions = [
            postal.subscribe({
                channel: "map",
                topic: "point.deleted",
                callback: function () {
                    getData(true);
                }
            }),
            postal.subscribe({
                channel: "map",
                topic: "zoom.changed",
                callback: function (data) {
                    setZoom(data.zoom);
                }
            })
        ]

        api.getProject(organisationId, projectId, (data) => {
            setProject(data);

            postal.publish({
                channel: "map",
                topic: "project.loaded",
                data: {
                    project: data
                }
            });

            setIsLoading(false);
        })

        api.getJobs(organisationId, projectId, (data) => {
            setJobs(data);
        })

        api.getSketches(organisationId, projectId, (data) => {
            setSketches(data);
        })

        api.getWorkflows(organisationId, projectId, (data) => {
            setWorkflows(data);
        })

        api.getUsers(organisationId, false, (data) => {
            setUsers(data);
        })

        api.getStamps(organisationId, (data) => {
            setStamps(data);
        })

        api.getTags(organisationId, (data) => {
            setTags(data);
        })

        return () => {
            subscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
        }
    }, []);

    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    const takeScreenshot = async function () {
        try {
            return new Promise(resolve => setTimeout(async () => {
                await (htmlToImage.toJpeg(document.querySelector(".map-container .leaflet-container"), { quality: 1 }).then(function (dataUrl) {
                    return resolve(dataUrl);
                }));
                return resolve();

            }, 1000));
        }
        catch (e) {
            console.log(e);
        }
    }

    const downloadScreenshot = () => {

        setIsDownloadingScreenshot(true);

        setTimeout(() => {

            takeScreenshot().then(result => {
                const link = document.createElement("a");
                link.href = result;
                link.download = `map-${moment().format("YYYYMMDD-HHmmss")}.jpg`;
                link.click();
                
                setIsDownloadingScreenshot(false);
            });
        }, 3000);

    }

    return <>
        <div className='sketch-wrapper'>
            <div className='sketch-filters px-3 py-3'>
                {project && project !== null &&
                    <SimpleBar autoHide={false} className='simplebar-no-autohide' style={{ maxHeight: '100%' }}>
                        <div className='d-flex'>
                            <h1 className="h5">{project.name}</h1>
                        </div>

                        <Form.Group controlId='input-small' className='mx-1 d-flex align-items-center'>
                            <Form.Label className='me-0 mb-0 me-3 pe-1 text-nowrap'>
                                Zoom:
                            </Form.Label>
                            <ReactSlider disabled={zoomLocked} className='range-slider range-slider-single' thumbClassName='range-slider-handle' trackClassName='range-slider-track' min={0} max={MaxZoom} value={zoom} ariaLabel={['Handle']} ariaValuetext={state => `Handle value ${state.valueNow}`} step={0.1} onAfterChange={onAfterZoomChange} onChange={onZoomChange} renderThumb={(props, state) => (
                                <div {...props}>
                                    <div className='range-slider-tooltip'>{state.valueNow}</div>
                                </div>
                            )} />

                            {zoomLocked && sketch && sketch !== null && <OverlayTrigger rootClose placement='bottom' trigger='click' overlay={
                                <Popover>
                                    <Popover.Header as='h3'>Zoom Lock</Popover.Header>
                                    <Popover.Body style={{ minWidth: "275px" }}>

                                        <label class="text-body fs-sm me-0 pe-1 text-nowrap form-label">Override</label>
                                        <ReactSlider className='range-slider range-slider-single mb-5' thumbClassName='range-slider-handle' trackClassName='range-slider-track' min={0} max={MaxZoom} value={zoom} ariaLabel={['Handle']} ariaValuetext={state => `Handle value ${state.valueNow}`} step={0.1} onAfterChange={onAfterZoomChange} onChange={onZoomChange} renderThumb={(props, state) => (
                                            <div {...props}>
                                                <div className='range-slider-tooltip'>{state.valueNow}</div>
                                            </div>
                                        )} />

                                        <div class="d-flex align-items-center justify-content-between mt-4">
                                            <Button variant='secondary btn-icon' size="sm" className='px-3' onClick={resetToLockedZoom}><i className='fi-rotate-left' /></Button>
                                            <Button variant='danger' size="sm" className='px-3' onClick={() => setZoomLocked(false)}><i className='fi-lock me-2' /> Unlock zoom</Button>
                                        </div>
                                    </Popover.Body>
                                </Popover>
                            }>
                                <Button variant='danger btn-icon shadow-sm' size="sm" className='ms-3 px-3' ><i className='fi-lock' /></Button>
                            </OverlayTrigger>
                            }
                            {(!zoomLocked || !sketch || sketch === null) &&
                                <Button variant='success btn-icon shadow-sm' size="sm" className='ms-3 px-3' onClick={() => setZoomLocked(true)} disabled={!sketch || sketch === null}><i className='fi-unlock' /></Button>
                            }

                        </Form.Group>

                        <Accordion>


                            <AddressSearchToolbox />
                            <FiltersToolbox
                                jobs={jobs} selectedJobs={selectedJobs} onJobsSelected={setSelectedJobs}
                                users={users} selectedUsers={selectedUsers} onUsersSelected={setSelectedUsers}
                                workflows={workflows} workflowPoints={workflowPoints} onWorkflowPointsSelected={setWorkflowPoints} workflowLines={workflowLines} onWorkflowLinesSelected={setWorkflowLines} workflowPointsLines={workflowPointsLines} onWorkflowPointsLinesSelected={setWorkflowPointsLines}
                                dateFrom={dateFrom} onDateFromSelected={setDateFrom} dateTo={dateTo} onDateToSelected={setDateTo} />
                            <SketchesToolbox projectId={projectId} organisationId={organisationId} sketches={sketches} sketchId={sketchId} sketch={sketch} key={hash({ sketches, sketchId, sketch })} onSketchSelected={setSketchId} onSketchDeleted={deleteSketch} onRefreshSketches={refreshSketches} />
                            <LimitsToolbox sketch={sketch} activeLimit={activeLimit} onActiveLimitSelected={setActiveLimit} />
                            <DrawingToolsToolbox sketch={sketch} />
                            <StampsToolbox sketch={sketch} stamps={stamps} tags={tags} />
                            <MapOutputToolbox isDownloadingMapPackage={isDownloadingMapPackage} sketch={sketch} onDownloadData={setIsDownloadingData} onDownloadMapPackage={() => setIsDownloadingMapPackage(true)} onDownloadScreenshot={() => downloadScreenshot()} />
                            <SettingsToolbox
                                basemap={basemap} onBasemapChange={setBasemap}
                                printBasemap={printBasemap} onPrintBasemapChange={setPrintBasemap}
                                snappingTolerance={snappingTolerance} onSnappingToleranceChange={setSnappingTolerance}
                                arrowScale={arrowScale} onArrowScaleChange={setArrowScale} onAfterArrowScaleChange={onAfterArrowScaleChange}
                                symbolScale={symbolScale} onSymbolScaleChange={setSymbolScale}
                                center={center} />
                        </Accordion>
                    </SimpleBar>
                }
            </div>
            <div className="map-container">
                <MapContainer center={center} zoom={zoom} style={containerStyle} dragging={true} maxZoom={MaxZoom} ref={mapRef} zoomSnap={0.1} zoomDelta={0.1}>
                    <RemoteControl onBoundsChange={onBoundsChange} onZoomChange={setZoom} />
                    <Basemap basemap={(isDownloadingMapPackage || isDownloadingScreenshot) ? printBasemap : basemap} zoom={zoom} isDownloadingMapPackage={isDownloadingMapPackage || isDownloadingScreenshot} maxZoom={MaxZoom} />

                    {!ArrayUtilities.isNullOrEmpty(lines) && <> {lines.map((lineId, index) => <DataGeomtry index={index} key={`line-${lineId}-${pointsHash}`} user={props.user} organisationId={organisationId} projectId={projectId} lineId={lineId} points={points} lines={lines} workflows={workflows} workflowLines={workflowLines} sketch={sketch} symbolScale={symbolScale} snappingTolerance={snappingTolerance} onSave={onSaveData} />)} </>}
                    {!ArrayUtilities.isNullOrEmpty(points) && <> {points.map((point, index) => <DataPoint index={index} key={`point-${point.id}-${pointsHash}`} user={props.user} organisationId={organisationId} projectId={projectId} point={point} points={points} lines={lines} workflows={workflows} workflowPoints={workflowPoints} workflowPointsLines={workflowPointsLines} sketch={sketch} symbolScale={symbolScale} snappingTolerance={snappingTolerance} onSave={onSaveData} />)} </>}
                    {sketch && sketch !== null &&
                        <Sketch
                            sketch={sketch}
                            onSave={onSaveSketch}
                            key={sketchHash}
                            project={project}
                            workflows={workflows}
                            points={points}
                            lines={lines}
                            snappingTolerance={snappingTolerance}
                            workflowLines={workflowLines}
                            workflowPoints={workflowPoints}
                            workflowPointsLines={workflowPointsLines}
                            arrowScale={arrowScale}
                            symbolScale={symbolScale}
                            stamps={stamps}
                            activeLimit={activeLimit} />
                    }

                    <Pane name="dimension-line-icons" style={{ zIndex: 5000 }} />
                    <Pane name="drawing-tool-labels" style={{ zIndex: 5001 }} />

                    {isDownloadingMapPackage &&
                        <DownloadMapPackage
                            project={project}
                            points={points}
                            sketch={sketch}
                            workflows={workflows}
                            workflowPoints={workflowPoints}
                            workflowLines={workflowLines}
                            workflowPointsLines={workflowPointsLines}
                            onComplete={() => setIsDownloadingMapPackage(false)} />
                    }

                    {isDownloadingData && isDownloadingData !== null &&
                        <DownloadData
                            organisationId={organisationId}
                            projectId={projectId}
                            selectedJobs={selectedJobs}
                            selectedUsers={selectedUsers}
                            mapRef={mapRef}
                            dateFrom={dateFrom}
                            dateTo={dateTo}

                            exportFormat={isDownloadingData}
                            onComplete={() => setIsDownloadingData(null)} />
                    }
                </MapContainer>

                <div className='map-messages-container'>
                    {hiddenDataPoints > 0 &&
                        <Alert variant='info' className='d-flex'>
                            <i className='fi-alert-circle me-2 me-sm-3 lead'></i>
                            <div>You are zoomed out too far, not all data displayed ({stringUtilities.formatDecimal(hiddenDataPoints, 0)} data points hidden). <Alert.Link href='#' onClick={() => onAfterZoomChange(zoom + 1)}>Zoom in</Alert.Link></div>
                        </Alert>
                    }
                </div>

                {(isLoading || isDownloadingScreenshot /* || isDownloadingMapPackage*/) &&
                    <Loading blur />
                }
            </div>
        </div>
    </>;

}