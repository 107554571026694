import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import useApi from "../utilities/useApi";
import { SurfaceAddEdit } from "./common/formsCreateEdit/surfaceAddEdit";
import stringUtilities from "../utilities/stringUtilities";

export default function EditSurface() {

    const { organisationId, projectId, surfaceId } = useParams();
    const navigate = useNavigate();

    const { api } = useApi();
    const [name, setName] = useState("");
    const [inputFile, setInputFile] = useState(null);
    const [filename, setFilename] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [canSave, setCanSave] = useState(false);
    const inputRef = useRef();
    const handleChange = () => {
        if (inputRef.current && inputRef.current.files[0]) {
            const inputFile = inputRef.current.files[0];
            if (inputFile.name.toLowerCase().endsWith(".csv")) {
                setInputFile(inputFile);
                setFilename(inputFile.name);
                setErrorMessage(null);
            } else {
                setErrorMessage("Invalid file extension. File should be of type .csv");
                setFilename("");
            }
        }
    }

    useEffect(() => {
        api.getSurface(
            organisationId, projectId, surfaceId,
            data => setName(data.name),
            () => {}
        )
    }, [])

    const handleSubmit = () => {
        if (inputFile && name) {
            const formData = new FormData();
            formData.append('File', inputFile);
            formData.append('Name', name);
            api.editSurface(
                organisationId, projectId, surfaceId, formData,
                () => navigate(`/${ organisationId }/projects/${ projectId }/surfaces`),
                () => {}
            )
        }
    }

    useEffect(() => setCanSave(!stringUtilities.isNullOrEmpty(name) && filename.toLowerCase().endsWith(".csv")), [name, filename])

    return (
        <SurfaceAddEdit
            title="Edit surface"
            canSave={ canSave }
            handleCancel={ () => navigate(`/${ organisationId }/projects/${ projectId }/surfaces`)}
            handleChange={ e => handleChange(e) }
            handleSubmit={ handleSubmit }
            name={ name }
            setName={ setName }
            filename={filename}
            inputRef={inputRef}
            errorMessage={errorMessage}
        />
    )
}