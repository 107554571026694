import { Button, Form, Modal } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import Textbox from "./form/textbox";
import { useEffect, useState } from "react";
import stringUtilities from "../utilities/stringUtilities";

export default function CopyProjectModal({name, onCancel, onCopy}) {

    const [newName, setNewName] = useState(`${name} (Copy)`);

    const [canSave, setCanSave] = useState(false);

    useEffect(() => {
        setCanSave(!stringUtilities.isNullOrEmpty(newName))
    }, [newName])

    return <Modal show onHide={ onCancel } centered>
        <Modal.Header closeButton>
            <Modal.Title>Copy project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <SimpleBar autoHide={ false } className='simplebar-no-autohide pe-3' style={ { maxHeight: 'calc(100vh - 380px)' } }>
                <Textbox label="New Name" value={ newName } onChange={ setNewName } />
            </SimpleBar>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">
            <div>
                <Button size="sm" variant='secondary' onClick={ () => onCancel && onCancel() } className="me-2">Cancel</Button>
                <Button size="sm" variant='primary' onClick={ () => onCopy(newName) } disabled={ !canSave }>Copy</Button>
            </div>
        </Modal.Footer>
    </Modal>
}