import { Col, Container, Table, Button, Modal, Tab, Nav, Fade } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom'
import useApi from '../utilities/useApi';
import { useEffect, useState } from 'react';
import stringUtilities from '../utilities/stringUtilities';
import Loading from './common/loading';

export default function Workflows(props) {
    const navigate = useNavigate();
    const { organisationId, projectId } = useParams();
    const { api } = useApi();
    const [projectName, setProjectName] = useState(null);
    const [workflows, setWorkflows] = useState(null);
    const [modalWorkflow, setModalWorkflow] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [workflowTemplates, setWorkflowTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchWorkflows = () => {
        setIsLoading(true)
        api.getWorkflows(
            organisationId,
            projectId,
            (data) => { setWorkflows(data); setIsLoading(false) },
            () => { },
        )
    }

    const fetchProject = () => {
        api.getProject(
            organisationId, projectId,
            data => setProjectName(data.name)
        )
    }

    const fetchWorkflowTemplates = () => {
        setIsLoading(true);
        api.getWorkflowTemplates(
            organisationId,
            data => {setWorkflowTemplates(data); setIsLoading(false)},
            () => { }
        )
    }

    useEffect(() => {
        fetchProject();
        fetchWorkflows();
    }, [])

    const onDelete = () => {
        api.deleteWorkflow(
            organisationId,
            projectId,
            modalWorkflow.id,
            () => {
                fetchWorkflows();
                setModalWorkflow(null);
                setShowDeleteModal(false);
            },
            () => { }
        )
    }

    return (
        <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2'>
            <Col className='mb-5'>
                <div className='workflows-landing-page'>
                    <div className='d-flex justify-content-between my-5'>
                        { 
                            projectName &&
                                <>
                                    <h1 className="h2 m-0">{ projectName } - Workflows</h1>
                                    <div className='d-flex'>
                                        <Button style={ { marginRight: "10px" } } size='sm' variant='outline-primary' onClick={ () => navigate(`/${ props.organisationId }/projects`) }>
                                            <span className='d-flex align-items-center'><i className='fi-arrow-left me-2'></i>
                                                Back to Projects
                                            </span>
                                        </Button>
                                        <Button size='sm' onClick={ () => navigate(`/${ props.organisationId }/projects/${ projectId }/workflows/add`) }>
                                            <span className='d-flex align-items-center'><i className='fi-plus me-2'></i>
                                                Add Workflow
                                            </span>
                                        </Button>
                                    </div>
                                </>
                        }
                    </div>
                    <Tab.Container defaultActiveKey="workflows" transition={ Fade } onSelect={e => e === "workflows" ? fetchWorkflows() : fetchWorkflowTemplates()}>
                        <Nav variant='tabs'>
                            <Nav.Item>
                                <Nav.Link eventKey='workflows'>
                                    Workflows
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey='templates'>
                                    Templates
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="workflows">
                                {
                                    !isLoading ? (
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th className='text-center'>Order</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    workflows && workflows
                                                        .map(item => (
                                                            <tr key={ item.id }>
                                                                <td>{ item.name }</td>
                                                                <td className='text-center'>{ stringUtilities.formatDecimal(item.orderBy, 0) }</td>
                                                                <td className='text-end'>
                                                                    <Link to={ `/${ organisationId }/projects/${ projectId }/workflows/${ item.id }/edit` } variant='secondary' size="sm" className='me-2 btn btn-light-primary shadow-sm btn-sm'>
                                                                        <span className='d-flex align-items-center'><i className='fi-edit me-2'></i> Edit</span>
                                                                    </Link>
                                                                    <Button variant='outline-danger' size="sm" onClick={ () => {
                                                                        setModalWorkflow(item);
                                                                        setShowDeleteModal(true);
                                                                    } }>
                                                                        <span className='d-flex align-items-center'><i className='fi-trash me-2'></i> Delete</span>
                                                                    </Button>
                                                                </td>
                                                            </tr>)
                                                        )
                                                }
                                            </tbody>
                                        </Table>
                                    ) : <Loading />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="templates">
                                {
                                    !isLoading ? (
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th className='text-center'>Order</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    workflowTemplates && workflowTemplates
                                                        .map(item =>
                                                            <tr key={ item.id }>
                                                                <td>{ item.name }</td>
                                                                <td className='text-center'>{ stringUtilities.formatDecimal(item.orderBy, 0) }</td>
                                                                <td className='text-end'>
                                                                    <Link to={ `/${ organisationId }/projects/${ projectId }/workflow-templates/${ item.id }/edit` } variant='secondary' size="sm" className='me-2 btn btn-light-primary shadow-sm btn-sm'>
                                                                        <span className='d-flex align-items-center'><i className='fi-edit me-2'></i> Edit</span>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                }
                                            </tbody>
                                        </Table>
                                    ) : <Loading />
                                }
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </Col>
            {
                modalWorkflow &&
                <Modal show={ showDeleteModal } onHide={ () => {
                    setModalWorkflow(null);
                    setShowDeleteModal(false);
                } } centered>
                    <Modal.Header>
                        <Modal.Title>Are you sure you want to delete the Workflow "{ modalWorkflow.name }"?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button size="sm" variant='secondary' onClick={ () => {
                            setModalWorkflow(null);
                            setShowDeleteModal(false);
                        } }>Cancel</Button>
                        <Button size="sm" variant="primary" onClick={ onDelete }>Delete</Button>
                    </Modal.Footer>
                </Modal>
            }
        </Container>
    )
}