import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import useApi from "../utilities/useApi";
import stringUtilities from "../utilities/stringUtilities";
import { SurfaceAddEdit } from "./common/formsCreateEdit/surfaceAddEdit";

export default function AddSurface(props) {
    const { organisationId, projectId } = useParams();
    const navigate = useNavigate();

    const { api } = useApi();
    const [name, setName] = useState("");
    const [filename, setFilename] = useState("");
    const [inputFile, setInputFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [canSave, setCanSave] = useState(false);

    const inputRef = useRef();
    const handleChange = () => {
        if (inputRef.current && inputRef.current.files[0]) {
            const inputFile = inputRef.current.files[0];
            if (inputFile.name.toLowerCase().endsWith(".csv")) {
                setInputFile(inputFile);
                setFilename(inputFile.name);
                setErrorMessage(null);
            } else {
                setErrorMessage("Invalid file extension. File should be of type .csv");
                setFilename("");
            }
        }
    }

    const handleSubmit = () => {
        if (inputFile && name) {
            const formData = new FormData();
            formData.append('Name', name);
            formData.append('File', inputFile);
            api.addSurface(
                organisationId, projectId, formData,
                () => navigate(`/${ organisationId }/projects/${ projectId }/surfaces`),
                () => {}
            )
        }        
    }

    useEffect(() => setCanSave(!stringUtilities.isNullOrEmpty(name) && filename.toLowerCase().endsWith(".csv")), [name, filename])

    return (
        <SurfaceAddEdit
            title="Add surface"
            canSave={ canSave }
            handleCancel={ () => navigate(`/${ organisationId }/projects/${ projectId }/surfaces`) }
            handleChange={ () => handleChange() }
            handleSubmit={ handleSubmit }
            name={ name }
            filename={filename}
            setName={ setName }
            inputRef={inputRef}
            errorMessage={errorMessage}
        />
    )
}