import { useEffect, useState } from 'react';
import ReactSlider from "react-slider";
import { Col, Container, Row, Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import useApi from '../utilities/useApi';
import stringUtilities from '../utilities/stringUtilities';

export default function EditWorkflowTemplate() {
    const {organisationId, projectId, workflowTemplateId} = useParams();
    const navigate = useNavigate();
    const {api} = useApi();
    const [name, setName] = useState("");
    const [workflowType, setWorkflowType] = useState("9");
    const [hue, setHue] = useState(0);
    const [rgbColor, setRgbColor] = useState("#000000");
    const [order, setOrder] = useState("0");
    const [configuration, setConfiguration] = useState(null);
    const [canSave, setCanSave] = useState(false);
    const [jsonInvalid, setJsonInvalid] = useState(false);

    const initialiseWorkflowTypeValue = (type) => {
        return (type !== 9 && type !== 10) ? 9 : type
    }

    const fetchWorkflowTemplate = () => {
        api.getWorkflowTemplate(
            organisationId, workflowTemplateId,
            data => {
                setName(data.name);
                setWorkflowType(String(initialiseWorkflowTypeValue(data.workflowTypeId)));
                setHue(data.hue);
                setRgbColor(stringUtilities.checkColorHash(data.color));
                setOrder(data.orderBy);
                setConfiguration(data.configuration ?? null);
            },
            () => {}
        )
    }

    useEffect(() => fetchWorkflowTemplate(), [])

    useEffect(() => setCanSave(hue !== undefined && !stringUtilities.isNullOrEmpty(workflowType)), [workflowType, hue])

    const updateTemplate = () => {
        api.editWorkflowTemplate(
            organisationId, workflowTemplateId, name, parseInt(order), configuration, parseInt(workflowType), parseInt(hue), rgbColor,
            () => navigate(`/${ organisationId }/projects/${projectId}/workflows`),
            () => {}
        )
    }

    const handleSubmit = () => {
        if (!stringUtilities.isNullOrEmpty(configuration)) {
            try {
                JSON.parse(configuration);
                updateTemplate();
                setJsonInvalid(false);
            } catch (error) {
                setJsonInvalid(true);
            }   
        } else updateTemplate();
    }

    return (
        <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2'>
            <Row>
                <Col className='mb-5'>
                    <div className='d-flex justify-content-between my-5'>
                        <h1 className="h2 m-0">Edit Template</h1>
                    </div>

                    <div>
                        <Form.Group className='mb-3 row align-items-center'>
                            <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="workflow-name">
                                Name
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <Form.Control size='sm' value={ name } type="text" onChange={ (e) => setName(e.target.value) } />
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row align-items-center'>
                            <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="workflow-type">
                                Workflow Type
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <Form.Select size='sm' value={ workflowType } onChange={ (e) => setWorkflowType(e.target.value) }>
                                    <option value="9">Non-Sensor</option>
                                    <option value="10">Sensor</option>
                                </Form.Select>
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row align-items-center'>
                            <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="workflow-hue">
                                Hue
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <ReactSlider className='range-slider range-slider-single' thumbClassName='range-slider-handle' trackClassName='range-slider-track' min={ 0 } max={ 360 } value={ hue } ariaLabel={ ['Handle'] } ariaValuetext={ state => `Handle value ${ state.valueNow }` } step={ 1 } onChange={ (e) => setHue(e) } renderThumb={ (props, state) => (
                                    <div { ...props }>
                                        <div className='range-slider-tooltip'>{ state.valueNow }</div>
                                    </div>
                                ) }
                                />
                                <div style={ { backgroundColor: `hsl(${ hue }, 100%, 50%)`, width: "200px", height: "40px", marginLeft: "1rem", borderRadius: "0.30rem" } } />
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row align-items-center'>
                            <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="workflow-rgbcolor">
                                RGB Color
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <Form.Control style={ { width: "100px", marginRight: "1rem" } } size='lg' value={ rgbColor } type="color" onChange={ (e) => setRgbColor(e.target.value) } />
                                <p className="m-0">{ rgbColor }</p>
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row align-items-center'>
                            <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="workflow-order">
                                Order
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <Form.Control size='sm' value={ order } type="text" onChange={ (e) => setOrder(e.target.value) } />
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row align-items-center'>
                            <Form.Label className='form-label fs-sm col-form-label col-md-3' htmlFor="workflow-configuration">
                                Configuration
                            </Form.Label>
                            <div className="col-md-9 d-flex flex-column">
                                <Form.Control size='sm' value={ configuration } as="textarea" rows={ 10 } onChange={ (e) => setConfiguration(e.target.value) } />
                                {jsonInvalid && <small className='text-danger mt-1'>Invalid Configuration formatting. Please verify the JSON text is correctly formatted and re-submit.</small>}
                            </div>
                        </Form.Group>
                        <Form.Group className='mt-4 row justify-content-end'>
                        <div className="col-md-9">
                            <Button onClick={ () => navigate(`/${ organisationId }/projects/${projectId}/workflows`) } variant='outline-primary' size="sm">
                                Cancel
                            </Button>
                            <Button disabled={ !canSave } onClick={ handleSubmit } variant='primary' size='sm' className='ms-2'>
                                    Save template
                            </Button>
                        </div>
                    </Form.Group>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}