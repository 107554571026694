import { useRef, useMemo, useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import RemoteControl from '../../map/remoteControl'
export const ProjectAddEdit = ({title, name, setName, syncDataDays, setSyncDataDays, center, setCenter, canSave, handleSubmit, handleCancel}) => {
    const MaxZoom = 25;
    const [mapCenter, setMapCenter] = useState(null);
    const [zoom, setZoom] = useState(2);
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            setCenter(marker.getLatLng());
            setMapCenter(marker.getLatLng());
          }
        },
      }),
      [],
    )

    useEffect(() => {
        if (center) {
            if (center.lat === 0 && center.lng === 0) {
                setMapCenter({
                    lat: 49.767566,
                    lng: -96.809765
                })
                setZoom(4);
            } else {
                setMapCenter({
                    lat: center.lat,
                    lng: center.lng
                })
                setZoom(12);
            }
        }
    }, [center])

    const mapRef = useRef(null);
    const containerStyle = {
        width: '100%',
        height: '100%',
        cursor: 'crosshair'
    };
    return (
        <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2'>
            <Row>
                <Col className='mb-5'>
                    <div className='d-flex justify-content-between my-5'>
                        <h1 className="h2 m-0">{title}</h1>
                    </div>
                    <div>
                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-md-3' htmlFor="project-name">
                                Name
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <Form.Control size='sm' value={ name } type="text" onChange={ (e) => setName(e.target.value) } />
                            </div>
                        </Form.Group>

                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-md-3' htmlFor="center-longitude">
                                Center Point
                            </Form.Label>
                            {
                                mapCenter &&
                                <div className="col-md-9 d-flex flex-column" style={ { height: "400px" } }>
                                    <MapContainer center={ mapCenter } zoom={ zoom } style={ containerStyle } dragging={ true } maxZoom={ MaxZoom } ref={ mapRef } zoomSnap={ 0.1 } zoomDelta={ 0.1 }>
                                        <RemoteControl enableFlyToOnClick onClickSpot={ spot => {
                                            setCenter({
                                                lat: spot.lat,
                                                lng: spot.lng
                                            });
                                            setMapCenter({
                                                lat: spot.lat,
                                                lng: spot.lng
                                            })
                                        } } />
                                        <Marker position={ mapCenter } eventHandlers={eventHandlers} ref={markerRef} draggable={true} />
                                        <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" maxZoom={ MaxZoom } />
                                    </MapContainer>
                                </div>
                            }
                        </Form.Group>

                        <Form.Group className='mb-3 row'>
                            <Form.Label className='form-label fs-sm col-md-3' htmlFor="sync-data-days">
                                How many days of data to keep on handheld
                            </Form.Label>
                            <div className="col-md-9 d-flex align-items-center">
                                <Form.Control size='sm' value={ syncDataDays } type="number" set="1" onChange={ (e) => setSyncDataDays(e.target.value) } />
                            </div>
                        </Form.Group>
                    </div>
                    <Form.Group className='mt-4 row justify-content-end'>
                        <div className="col-md-9">
                            <Button onClick={ handleCancel } variant='outline-primary' size="sm">
                                Cancel
                            </Button>
                            <Button disabled={ !canSave } onClick={ handleSubmit } size='sm' className='ms-2'>
                                Submit
                            </Button>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
        </Container>
    )
}